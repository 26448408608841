import { httpsCallable } from "firebase/functions";
import { useContext, useEffect, useState } from "react";
import AppContext from "./AppContext";
import OfferForm from "./CheckoutForms/OfferForm";
import Deals from "./Deals";
import { functions } from "./firebase-config";

export default function Campaigns() {
  const { setActivePage } = useContext(AppContext);
  const [campaigns, setCampaigns] = useState<any[]>([]);
  const [selectedCampaign, setSelectedCampaign] = useState<any>();
  const [isLoading, setIsLoading] = useState(true);
  const [editingCampaign, setEditingCampaign] = useState<any>();

  useEffect(() => {
    getAllCampaigns().then((campaigns) => {
      const filteredCampaigns = (campaigns as any[]).filter(
        (campaign) => campaign.details != null
      );
      setCampaigns(filteredCampaigns);
    });
  }, []);

  const getAllCampaigns = async () => {
    setIsLoading(true);
    const allCampaigns = httpsCallable(functions, "allCampaigns");
    const result = await allCampaigns({});
    setIsLoading(false);
    return result.data;
  };

  const editCampaign = (campaign) => {
    setEditingCampaign(campaign);
  };

  const selectCampaign = (campaign) => {
    setSelectedCampaign(campaign);
  };

  if (editingCampaign) {
    return (
      <div className="p-12 mx-24">
        <div
          onClick={() => {
            setEditingCampaign(undefined);
            setSelectedCampaign(undefined);
          }}
          className="cursor-pointer ml-0 mr-auto w-fit mb-4 font-bold"
        >
          ← Back
        </div>
        <OfferForm
          campaign={editingCampaign}
          back={() => setEditingCampaign(undefined)}
          totalPrice={-1}
        />
      </div>
    );
  }

  if (selectedCampaign) {
    return (
      <Deals
        campaign={selectedCampaign}
        back={() => setSelectedCampaign(undefined)}
      />
    );
  }

  return (
    <div className="deals mt-4">
      <div className="flex">
        <div
          className={`text-white w-fit flex ml-12 mb-4 text-center font-semibold text-sm rounded px-5 py-3 hover:shadow-md cursor-pointer`}
          onClick={() => setActivePage("checkout")}
          style={{ backgroundColor: "rgb(119 51 226)" }}
        >
          <div className="my-auto">New Campaign</div>
        </div>
        <div
          className={`border w-fit flex ml-8 mb-4 text-center font-semibold text-sm rounded px-5 py-3 hover:shadow-md cursor-pointer`}
          onClick={() => setActivePage("deals")}
        >
          <div className="my-auto">Manage All Campaigns</div>
        </div>
      </div>
      <div className="relative overflow-x-auto w-full">
        {isLoading && (
          <div className="fixed w-full bg-clear h-screen z-10">
            <img src="./icons/ui/load.gif" className="w-16 mx-auto mt-12" />
          </div>
        )}
        <table
          className="mx-8 box-border text-sm text-left rtl:text-right text-gray-500"
          style={{ width: "calc(100% - 4rem)" }}
        >
          <thead className="text-xs text-gray-700 uppercase bg-gray-50">
            <tr>
              <th scope="col" className="px-6 py-3">
                Brand
              </th>
              <th scope="col" className="px-6 py-3">
                Title
              </th>
              <th scope="col" className="px-6 py-3">
                Completed / Accepted
              </th>
              <th scope="col" className="px-6 py-3">
                Views
              </th>
              <th scope="col" className="px-6 py-3">
                Clicks
              </th>
              <th scope="col" className="px-6 py-3">
                Actions
              </th>
            </tr>
          </thead>
          <tbody>
            {campaigns.map((campaign) => (
              <tr
                className={`bg-white border-b hover:bg-gray-100 cursor-pointer ${
                  isLoading && "blur-sm"
                }`}
                onClick={() => selectCampaign(campaign)}
                key={campaign.id}
              >
                <th
                  scope="row"
                  className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap"
                >
                  {campaign.details?.brand}
                </th>
                <td className="px-6 py-4">{campaign.details?.title}</td>
                <td className="px-6 py-4">
                  {campaign.results?.completions ?? 0} /{" "}
                  {campaign.results?.accepts ?? 0}
                </td>
                <td className="px-6 py-4">{campaign.results?.views ?? 0}</td>
                <td className="px-6 py-4">{campaign.results?.clicks ?? 0}</td>
                <td className="px-6 py-4">
                  <button
                    className="text-indigo-600 hover:text-indigo-900 mr-4"
                    onClick={() => editCampaign(campaign)}
                  >
                    Edit Details
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}
